import { Box, Stack } from "@chakra-ui/react"
import React from "react"
import { OrderEntity } from "~/interfaces/entities/Order"
import { StoreEntity } from "~/interfaces/entities/Store"
import OrderSummaryLineItems from "../checkout/order-summary/OrderSummaryLineItems"
import OrderSummaryTotals from "../checkout/order-summary/OrderSummaryTotals"

interface Props {
  store?: StoreEntity
  order: OrderEntity
  headingText?: string
}

const OrderCompleteSummary: React.FC<Props> = ({ order }) => {
  return (
    <Stack spacing={8}>
      <Box>
        <OrderSummaryLineItems order={order} />
        <OrderSummaryTotals
          isPendingAddress={order.isPendingAddress}
          freightFloat={order.data.freightFloat}
          freightHuman={order.data.freightHuman}
          taxInclusive={order.data.taxInclusive}
          taxTotalFloat={order.data.taxTotalFloat}
          taxTotalHuman={order.data.taxTotalHuman}
          totalHuman={order.data.totalHuman}
          couponCode={order.data.couponCode}
          discountFloat={order.data.discountFloat}
          printServiceId={order.data.printService}
          discountHuman={order.data.discountHuman}
          isUpdating={order.isUpdating}
        />
      </Box>
    </Stack>
  )
}

export default OrderCompleteSummary
